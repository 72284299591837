import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Add SEO meta tags and properties
document.title = "Chelsea Transfer News | Latest Rumours and Updates";
document.querySelector('meta[name="description"]').setAttribute("content", "Get the latest Chelsea transfer news, rumours, and updates. Follow us for all the breaking transfer news involving the Blues.");
//document.querySelector('link[rel="canonical"]').setAttribute("href", "https://www.chelsea-transfer.com");
//document.querySelector('meta[name="robots"]').setAttribute("content", "index, follow");


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
