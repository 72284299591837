import React, { useState, useEffect } from 'react';
import Parser from 'rss-parser';
import './RssFeeds.css';

function RssFeeds() {
  const [feeds, setFeeds] = useState([]);

  useEffect(() => {
    const parser = new Parser();
    const feedUrls = [
      'https://www.si.com/.rss/chelsea',
      'https://theprideoflondon.com/rss',
      'https://talkchelsea.net/feed/',
      'https://weaintgotnohistory.sbnation.com/rss/current',
      'https://www.football.london/chelsea-fc/?service=rss'
    ];

    async function fetchFeeds() {
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
      const promises = feedUrls.map(async (url) => {
        try {
          const feed = await parser.parseURL(proxyUrl + url);
          return feed;
        } catch (error) {
          console.error(`Error fetching feed from ${url}: ${error}`);
          return null;
        }
      });
      const feedData = await Promise.all(promises);
      const filteredFeeds = feedData.filter(feed => feed !== null);
      setFeeds(filteredFeeds);
    }
    
    fetchFeeds();
    
  }, []);

  return (
    <div className='RssFeeds'>
      <h2>RSS Feeds</h2>
      {feeds.length === 0 ? (
        <p>Loading...</p>
      ) : (
        <ul>
          {feeds.map((feed, index) => (
            <li key={index}>
              <a href={feed.link}>{feed.title}</a> | {feed.description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default RssFeeds;