import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer>
      <div className="footer-section">
        <h3>About Us</h3>
        <p>
          Welcome to Chelsea Transfer, your ultimate source for the latest transfer rumors and news about Chelsea Football Club. Our team of dedicated writers and editors work hard to provide you with timely and accurate updates on everything related to Chelsea's transfers, including insider information, analysis, and expert opinions. 
        </p>
      </div>
      <div className="footer-section">
        <h3>Contact Us</h3>
        <ul>
          <li>Phone: [TBD]</li>
          <li>Email: <a href="mailto:info@chelsea-transfer.com">info@chelsea-transfer.com</a></li>
          <li>Address: [TBD]</li>
        </ul>
      </div>
      <div className="footer-section">
        <h3>Syndication</h3>
        <a href="/rss">Subscribe via RSS[TBD]</a>
        <p>
        <h4>About</h4>
         Serviced by: <a href="https://www.underdog-sc.com/">Underdog Sports Consulting Football Agency</a> and <a href="https://www.everymansci.com/">Everyman Science - Daily Science and Technology Magazine</a>
        </p>
       
      </div>
      
    </footer>
  );
}


export default Footer;
