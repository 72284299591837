import React from 'react';
import './CurrentSquad.css'

function CurrentSquad({ squad }) {
  return (
    <div className='CurrentSquad'>
      <h2>Current Squad</h2>
      {squad.length === 0 ? (
        <p>Loading...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Age</th>
              <th>Position</th>
              <th>Market Value</th>
            </tr>
          </thead>
          <tbody>
            {squad.map((player, index) => (
              <tr key={index}>
                <td>{player.name}</td>
                <td>{player.age}</td>
                <td>{player.position}</td>
                <td>{player.value.value/1000000 + "m " + player.value.currency}</td>

              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default CurrentSquad;
