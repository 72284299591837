import React from 'react';
import './ArrivalsDepartures.css'

function ArrivalsDepartures({ arrivals, departures }) {
  const totalArrivalsValue = arrivals.reduce((total, player) => total + (player.value.value || 0), 0);
  const totalDeparturesValue = departures.reduce((total, player) => total + (player.value.value || 0), 0);
  const seasonBalance = totalDeparturesValue- totalArrivalsValue;

  return (
    <div className ="rumored-players-container">
    <div className='arrivals-departures'>
      <h2>Season Balance: <span style={{ color: seasonBalance >= 0 ? 'darkgreen' : 'darkred' }}>{seasonBalance / 1000000 + "m €"}</span></h2>
      <h2>Arrivals</h2>
      {arrivals.length === 0 ? (
        <p>Loading...</p>
      ) : (
        <div className='table-container'>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Age</th>
              <th>Nationality</th>
              <th>Former Club</th>
              <th>Transfer Fee</th>
            </tr>
          </thead>
          <tbody>
            {arrivals.map((player, index) => (
              <tr key={index}>
                <td>{player.name}</td>
                <td>{player.age}</td>
                <td>{player.nations[0]}</td>
                <td>{player.former_club}</td>
                <td>{player.value.value ? player.value.value/1000000 + "m " + player.value.currency : ""}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      )}

      <h2>Departures</h2>
      {departures.length === 0 ? (
        <p>Loading...</p>
      ) : (
        <div className='table-container'>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Age</th>
              <th>Nationality</th>
              <th>Former Club</th>
              <th>Transfer Fee</th>
            </tr>
          </thead>
          <tbody>
            {departures.map((player, index) => (
              <tr key={index}>
                <td>{player.name}</td>
                <td>{player.age}</td>
                <td>{player.nations[0]}</td>
                <td>{player.former_club}</td>
                <td>{player.value.value ? player.value.value/1000000 + "m " + player.value.currency : ""}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      )}
    </div>
    </div>
  );

}

export default ArrivalsDepartures;

