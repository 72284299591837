import React from 'react';
import './RumoredPlayers.css';
import { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyC73VIdKM3AMZkbY9XiGnbgff7yRSXdM2k",
  authDomain: "chelsea-transfer.firebaseapp.com",
  projectId: "chelsea-transfer",
  storageBucket: "chelsea-transfer.appspot.com",
  messagingSenderId: "1025843614698",
  appId: "1:1025843614698:web:7476a4c9dbb6597aaad893",
  measurementId: "G-KGVPM5P2X4"
};
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

function RumoredPlayers({ players }) {
  const [rumorVotes, setRumorVotes] = useState([]);

  useEffect(() => {
    // listen for changes to the votes collection
    const unsubscribe = db.collection('votesdb').onSnapshot(querySnapshot => {
      const votes = querySnapshot.docs.map(doc => doc.data().votes);
      setRumorVotes(votes);
    });
    return unsubscribe;    // clean up the subscription on unmount
  }, []);

  const handleVote = async (index, increment) => {
    try {
      db.collection('votesdb').doc(index.toString()).set({
        votes: firebase.firestore.FieldValue.increment(increment)
      }, { merge: true });
    } catch (error) {
      console.log(error);
    }
  };

  const getColor = (votes) => {
    if (votes === 0) {
      return 'black';
    } else if (votes > 0) {
      return 'darkgreen';
    } else if (votes < 0) {
      return 'darkred';
    }
  };

  return (
    <div className="rumored-players-container">
      <h2>Rumored Players</h2>
      {players.length === 0 ? (
        <p>Loading...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Vote</th>
              <th>Name</th>
              <th>Age</th>
              <th>Value</th>
              <th>Current Club</th>
              <th>Rumour Date</th>
            </tr>
          </thead>
          <tbody>
            {players.map((player, index) => (
              <tr key={index}>
                <td>
                  <div style={{ textAlign: 'center' }}>
                    <button className="vote-button" onClick={() => handleVote(index, 1)}>Upvote</button>
                    <button className="vote-button" onClick={() => handleVote(index, -1)}>Downvote</button>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ color: getColor(rumorVotes[index]), fontWeight: 'bold' }}>{rumorVotes[index]}</span>
                  </div>
                </td>
                <td>{player.name}</td>
                <td>{player.age}</td>
                <td>{(player.value && player.value.value > 0) ? player.value.value / 1000000 + "m " + player.value.currency : ""}</td>
                <td>{player.former_club}</td>
                <td>{player.most_recent_source ? Math.round((Date.now() - new Date(player.most_recent_source)) / (1000 * 60 * 60 * 24)) + " days ago" : "no source"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default RumoredPlayers;