import React from 'react';
import './Header.css';
import { FaFacebookSquare, FaTwitterSquare } from 'react-icons/fa';

const url = "https://chelsea-transfer.com";
const title = "Latest Chelsea Transfer News and Rumors";

function Header() {
  return (
    <div className='Header'>
      <div className='Header-title'>
        
        <h1>Chelsea Transfer News and Rumours</h1>
        <h2>Latest FC Chelsea Transfer Market News and Rumours</h2>
      </div>
      <div className='Header-ads'>
      <iframe src = "amazonad.html" style={{
        maxWidth: 1300,
        width: 800,
        overflow: "auto",
        border:0,
        height:260
      }}></iframe>
      </div>
      <div style={{ textAlign: 'center' }}>
        <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank" rel="noopener noreferrer">
          <FaFacebookSquare size={44} />
        </a>

        <a href={`https://twitter.com/share?url=${url}&text=${title}`} target="_blank" rel="noopener noreferrer">
          <FaTwitterSquare size={44} />
        </a>

      </div>
    </div>
  );
}

export default Header;
