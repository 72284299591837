
import React, { useState, useEffect } from 'react';
import RumoredPlayers from './components/RumoredPlayers';
import ArrivalsDepartures from './components/ArrivalsDepartures';
import CurrentSquad from './components/CurrentSquad';
import RssFeeds from './components/RssFeeds';
import Header from './components/Header';
import Footer from './components/Footer';
import { Helmet } from 'react-helmet';
import PlayerLineup from './components/PlayerLineup';

import './App.css';

function App() {
  // Define state for each component's data
  const [rumoredPlayers, setRumoredPlayers] = useState([]);
  const [arrivals, setArrivals] = useState([]);
  const [departures, setDepartures] = useState([]);
  const [squad, setSquad] = useState([]);
  const [playersForLineup, setPlayersForLineup] = useState([]);

  function transformForLineup(players) {
    const homeTeam = {
      color: "lightblue",
      squad: {
        cam: [],
        df: [],
        fw: [],
        gk: []
      }
    };
  
    players.forEach(player => {
      if (player.position === "GK") {
        homeTeam.squad.gk = { name: player.name };
      } else {
        homeTeam.squad[player.position.toLowerCase()].push({
          number: player.shirtNumber,
          name: player.name
        });
      }
    });
  
    return homeTeam;
  }
  
  

  // Fetch data and update state
  useEffect(() => {

    fetch('club.json')
    .then(response => response.json())
    .then(data => {
      setArrivals(data.arrivals);
      setDepartures(data.departures);
      setSquad(data.players);
      setRumoredPlayers(data.rumored_players);
      // setPlayersForLineup(transformForLineup(data.rumored_players))
    })
    .catch(error => console.log(error));


    
  }, []);

  return (
    <>
      <Helmet>
        <title>Chelsea Transfer News | Latest Rumours and Updates</title>
        <meta name="description" content="Get the latest Chelsea transfer news, rumours, and updates. Follow us for all the breaking transfer news involving the Blues." />
        <link rel="canonical" href="https://www.chelsea-transfer.com" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className='App'>
        <Header />
        <RumoredPlayers players={rumoredPlayers} />
        <ArrivalsDepartures arrivals={arrivals} departures={departures} />
        {/* <PlayerLineup players={playersForLineup} /> */}
        <CurrentSquad squad={squad} />
        {/* <RssFeeds /> */}
        <div style={{margin:10}}></div>
        <Footer />
      </div>
    </>
  );
}

export default App;
